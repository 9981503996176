import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";

import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_16/b16_1.webp");
const image2 = require("../../../assets/images/blog_16/b16_2.webp");
const image3 = require("../../../assets/images/blog_16/b16_3.jpg");
const image4 = require("../../../assets/images/blog_16/b16_4.jpg");
const image5 = require("../../../assets/images/blog_16/b16_5.jpg");
const image6 = require("../../../assets/images/blog_16/b16_6.jpg");
const image7 = require("../../../assets/images/blog_16/b16_7.jpg");
const image8 = require("../../../assets/images/blog_16/b16_8.jpg");
const image9 = require("../../../assets/images/blog_16/b16_9.webp");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Integrate SharePoint with Chatbot | Workativ Blog"
        description="Effortlessly create Microsoft SharePoint workflow automations and deploy a contextual chatbot for employee support."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Integrate and Automate your favorite apps with
                            Microsoft SharePoint using Workativ
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Microsoft’s SharePoint is a robust business platform
                          that offers improved collaboration, content
                          management, search and business intelligence
                          capabilities to organizations all over the world. Some
                          of the leading companies are already leveraging these
                          capabilities of SharePoint to increase their business
                          productivity as well as efficiency.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          However, for many users SharePoint is somewhat
                          difficult to navigate. It is tedious to maintain
                          SharePoint sites and time-consuming to monitor the
                          status of their documents. Here is where Workativ’s
                          process automation comes into the picture.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ is a no-code platform for scaling your IT &
                          HR support with AI-based self-service automation.
                          Delivering remote support for employees is more
                          important than ever and Workativ can help automate
                          employee support with intelligent chatbot and process
                          automation, out-of-the-box. Workativ can be added to
                          any of your instant messaging apps like Slack or
                          Microsoft Teams so that employees can self-serve their
                          IT/HR issues, on-the-go, anytime.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Here are just some of the ways you can streamline your
                          SharePoint-related processes using Workativ’s process
                          automation:
                        </p>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Implement code-free SharePoint workflow automation
                          </li>
                          <li>
                            Eliminate latency, errors, and operational costs
                          </li>
                          <li>Retrieve and control SharePoint workflows</li>
                          <li>Keep tabs with SharePoint workflow management</li>
                          <li>Check and retrieve files automatically</li>
                          <li>And more!</li>
                        </ul>
                        <p class="font-section-normal-text line-height-2">
                          If your business is using a document management
                          software like Microsoft Sharepoint, it is high-time
                          you integrated it with Workativ to reap the benefits
                          of automation.
                        </p>
                      </div>
                      <div>
                        <h2 class="font-section-sub-header-small">
                          How Workativ streamlines key SharePoint processes
                        </h2>
                        <img loading="lazy" src={image2} className="mb-5"></img>
                        <p class="font-section-normal-text line-height-2">
                          By integrating Microsoft SharePoint and Workativ, you
                          streamline your workplace IT support to connect
                          employees, processes, and automations into a
                          transparent workflow. Let’s take a look at some of the
                          automations you get with Workativ and Microsoft
                          SharePoint integration.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Get File Link
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Whether it’d be to quickly look up an FAQ document or
                          a Product Requirements Document or any kind of
                          document for the matter, employees don’t have to log
                          in to their SharePoint portal each and every time to
                          look up a document. Instead, with Workativ’s
                          SharePoint process automation, employees can ask
                          Workativ to bring up a file link on their Slack or
                          Microsoft Teams app and Workativ will immediately
                          fetch and display the link from SharePoint in the
                          blink of an eye!
                        </p>

                        <h5 class="font-section-sub-header-small">
                          2. Get Documents
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Whenever an employee needs to download a document from
                          SharePoint on to their device, they can use Workativ
                          to do so in an instant. Employees will just have to
                          ask Workativ like “Hey, I need to download xyz
                          document” and Workativ will get to action and fetch
                          and display the document for download to the employee
                          on their Slack or Microsoft Teams app.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          Popular Microsoft SharePoint Integrations
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ, you can connect multiple apps and set
                          up an automation involving a synergistic workflow of
                          tasks on those apps. Let’s take a look at some of the
                          apps you can combine with Microsoft SharePoint using
                          Workativ.
                        </p>
                        <ExistingBlogCta
                          ContentCta="Experience Seamless Integration with Microsoft SharePoint."
                          ButtonText="Book a Demo"
                          isColor="white"
                          backgroundImage={cta_2}
                          mobileBackgroundImage={cta_2_mob}
                        />
                        <h5 class="font-section-sub-header-small">
                          1. SharePoint and ServiceNow integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ, you can set up a SharePoint and
                          ServiceNow automation such that, in case the
                          SharePoint automation doesn’t behave as intended, a
                          ticket gets automatically created on ServiceNow giving
                          the help desk team a head-up about the situation so
                          that help desk agents can take over and look into the
                          issue immediately.
                        </p>
                        <img loading="lazy" src={image3} className="mb-5"></img>

                        <h5 class="font-section-sub-header-small">
                          2. Jira SharePoint integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          You can set up a SharePoint and Jira automation using
                          Workativ such that, whenever there’s an important
                          product-related update to be shared with the dev team,
                          after the product manager uploads the update document
                          into Sharepoint, it gets shared with the entire dev
                          team via automatic Jira ticket creation by Workativ.
                          Sounds so simple, right?
                        </p>
                        <img loading="lazy" src={image4} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          3. SharePoint integration with Slack
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Whenever there’s a new employee being onboarded, help
                          desk agents can set up an automation on Workativ by
                          integrating Slack and SharePoint such that, the new
                          employee guidelines document from SharePoint gets
                          shared with the employee on their Slack channel by
                          Workativ. This prevents the employee from going on a
                          search spree on what are the company guidelines to be
                          followed.
                        </p>
                        <img loading="lazy" src={image5} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          4. Freshdesk SharePoint integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Using Workativ, you can set up a SharePoint and
                          Freshdesk automation such that, in case the SharePoint
                          automation doesn’t behave as intended, a ticket gets
                          automatically created on Freshdesk informing the help
                          desk team that the automation has gone haywire so that
                          they can step-in and resolve the issue quickly
                        </p>

                        <img loading="lazy" src={image6} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          How to automate SharePoint processes with Workativ
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen the endless possibilities of what
                          integrating Workativ and Microsoft SharePoint and your
                          existing apps can do for you, let’s see how you can go
                          about the same.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading="lazy" src={image7} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>
                        <img loading="lazy" src={image8} className="mb-3"></img>
                        <img loading="lazy" src={image9} className="mb-5"></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        Benefits
                      </h6>

                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. Easy Accessibility
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Users can easily and quickly access all documents
                          stored on SharePoint. For instance, if any employee is
                          seeking team related details, then the data is easily
                          accessible on Workativ.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. Creating a Synergy between your Apps
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          You can combine your other apps with Microsoft
                          SharePoint creating an automated workflow that does
                          much more with those apps in just a single run.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. Leverage SharePoint infrastructure
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          We designed Workativ for SharePoint to be simple to
                          use, easy to manage, and require minimal effort to get
                          up and running. There’s no need to install additional
                          software – our automation designer is native to
                          SharePoint and allows you to build simple to
                          sophisticated workflows quickly and easily.
                        </p>

                        <h5 className="font-section-sub-header-small">
                          4. Microsoft SharePoint Employee Self Service
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          Workativ provides users with a holistic self-service
                          interface for all of their IT needs. By integrating
                          SharePoint with Workativ, end users can retrieve and
                          view files with ease. This integration saves time and
                          greatly enhances the user experience.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Liked what you read about Workativ? Try Workativ today
                          by signing up for a FREE trial at workativ.com and
                          experience the difference yourself.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free SharePoint Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="sharepoint chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
